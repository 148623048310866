var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("page-header", {
        attrs: {
          title: "불편한 점이 있으신가요?",
          subtitle: "문의 및 개선사항 등 무엇이든\n자유롭게 말씀주세요."
        }
      }),
      _c("div", { staticClass: "ui-border-line ui-mt-4" }),
      _c("div", { staticClass: "ui-inputbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.email,
              expression: "formData.email"
            }
          ],
          attrs: { type: "text", placeholder: "", required: "" },
          domProps: { value: _vm.formData.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "email", $event.target.value)
            }
          }
        })
      ]),
      _c("div", { staticClass: "ui-border-line" }),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formData.detail,
            expression: "formData.detail"
          }
        ],
        attrs: { placeholder: "문의 내용을 입력해주세요", required: "" },
        domProps: { value: _vm.formData.detail },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.formData, "detail", $event.target.value)
          }
        }
      }),
      _c("div", { staticClass: "ui-border-line" }),
      _c("footer-box", {
        attrs: {
          submitText: "의견 보내기",
          submitCb: function() {
            return _vm.onSubmit()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }