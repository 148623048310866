<template>
  <div class="container">
    <page-header
      :title="`불편한 점이 있으신가요?`"
      :subtitle="`문의 및 개선사항 등 무엇이든\n자유롭게 말씀주세요.`"
    >
    </page-header>

    <div class="ui-border-line ui-mt-4"></div>

    <div class="ui-inputbox">
      <input type="text" v-model="formData.email" placeholder="" required>
    </div>

    <div class="ui-border-line"></div>

    <textarea
      placeholder="문의 내용을 입력해주세요"
      v-model="formData.detail"
      required
    >
    </textarea>

    <div class="ui-border-line"></div>

    <footer-box
      submitText="의견 보내기"
      :submitCb="() => onSubmit()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import device from 'current-device'

export default {
  components: {
    PageHeader,
    FooterBox,
  },
  data() {
    const userData = this.$store.state.user.userData
    const platform = sessionStorage.getItem('platform')
    return {
      formData: {
        name: userData.userName, // 이름
        email: userData.phone, // 전화번호
        detail: '', // 내용
        device: platform, // 디바이스종류
        os: device.os, // OS정보
      },
    }
  },
  computed: {
    validate() {
      return this.formData.detail && this.formData.email
    },
  },
  methods: {
    onSubmit() {
      const req = {
        method: 'post',
        url: `/user/contact`,
        data: this.formData,
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.submitCb()
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    submitCb() {
      this.$toast('의견이 전송되었습니다').back()
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  line-height: 3rem;
  padding: 3rem 0;
  padding: 3rem 0;
  border: 0;
  width: 100%;
  min-height: 20rem;
  font-size: 1.6rem;
}
</style>
